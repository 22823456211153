import { Controller } from "stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = ["select", "form"]

  readonly selectTarget: HTMLSelectElement
  readonly selectTargets: HTMLSelectElement[]
  readonly hasSelectTarget: boolean

  readonly formTarget: HTMLFormElement
  readonly formTargets: HTMLFormElement[]
  readonly hasFormTarget: boolean

  fetchData() {
    const userId = this.selectValue()
    const path = `${this.formTarget.action}?user_id=${userId}`

    Turbolinks.visit(path, { action: "replace" })
  }

  selectValue() {
    const selectInput = this.selectTarget
    return selectInput.options[selectInput.selectedIndex].value
  }
}
