import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["error"]

  readonly errorTarget: Element
  readonly errorTargets: Element[]
  readonly hasErrorTarget: boolean

  waiter: ReturnType<typeof setTimeout> | null = null;

  connect() {
    this.showErrors()
    this.refreshTable()
  }

  disconnect() {
    if (this.waiter) {
      clearTimeout(this.waiter)
    }
  }

  refreshTable() {
    this.wait(parseInt(this.data.get("refreshDelay")))
      .then(() => this.increaseDelayForNextTime())
      .then(() => this.load())
      .then(() => this.refreshTable())
  }

  wait(delay: number) {
    return new Promise(
      (resolve) =>
      (this.waiter = setTimeout(() => {
        this.waiter = null
        resolve()
      }, delay))
    )
  }

  increaseDelayForNextTime() {
    const refreshDelay = parseInt(this.data.get("refreshDelay"))
    const refreshScale = parseInt(this.data.get("refreshScale"))
    this.data.set("refreshDelay", (refreshDelay * refreshScale).toString())
  }

  load() {
    fetch(this.data.get("path"))
      .then((response) => response.text())
      .then((html) => (this.element.innerHTML = html))
      .then(() => this.showErrors())
  }

  click(e: MouseEvent) {
    const eventTarget = e.target as HTMLTableRowElement
    if (eventTarget.classList.contains("delete")) return

    const reportId = eventTarget.closest("tr").getAttribute("data-report-id")
    this.toggleErrorVisibility(reportId)
  }

  toggleErrorVisibility(reportId: string) {
    const current = this.data.get(`showError-${reportId}`)
    this.data.set(`showError-${reportId}`, current == "true" ? "false" : "true")
    this.showErrors()
  }

  showErrors() {
    this.errorTargets.forEach((target) => {
      const reportId = target.getAttribute("data-report-id")
      const show = this.data.get(`showError-${reportId}`) == "true"
      target.classList.toggle("show-error", show)
    })
  }
}
