import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["label", "upload"]

  readonly labelTarget: HTMLElement
  readonly labelTargets: HTMLElement[]
  readonly hasLabelTarget: boolean

  readonly uploadTarget: HTMLInputElement
  readonly uploadTargets: HTMLInputElement[]
  readonly hasUploadTarget: boolean

  selectFile() {
    this.uploadTarget.click()
  }

  updateFilename() {
    const name = this.uploadTarget.value.split("\\").pop().split(".")
    this.labelTarget.querySelector(".format").innerHTML = name.pop()
    this.labelTarget.querySelector(".filename").innerHTML = name.join(".")
    this.labelTarget.classList.toggle("empty", false)
  }
}
