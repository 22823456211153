import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button", "form", "input"]

  readonly buttonTarget: Element
  readonly buttonTargets: Element[]
  readonly hasButtonTarget: boolean

  readonly formTarget: Element
  readonly formTargets: Element[]
  readonly hasFormTarget: boolean

  readonly inputTarget: Element
  readonly inputTargets: Element[]
  readonly hasInputTarget: boolean

  applyInputStyles() {
    this.inputTarget.classList.toggle("input", true)
    this.buttonTarget.classList.toggle("displayed-button", true)
  }

  removeInputStyles() {
    this.inputTarget.classList.toggle("input", false)
    this.buttonTarget.classList.toggle("displayed-button", false)
  }

  toggleSaveButtonVisibility() {
    this.buttonTarget.classList.toggle("displayed-button")
  }

  updateClientNotes(event) {
    this.removeInputStyles()

    const [_, status, xhr] = event.detail
    if (status == "OK") {
      const newHtml = xhr.response
      this.element.outerHTML = newHtml
      this.element.classList.toggle("error", false)
    } else {
      this.element.classList.toggle("error", true)
    }
  }
}
