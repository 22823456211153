import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["form"]

  readonly formTarget: Element
  readonly formTargets: Element[]
  readonly hasFormTarget: boolean

  updateStatus() {
    Rails.fire(this.formTarget, "submit")
  }

  updateSuccess(event) {
    const [_, status, xhr] = event.detail
    if (status == "OK") {
      const newHtml = xhr.response
      this.element.outerHTML = newHtml
      this.element.classList.toggle("error", false)
    } else {
      this.element.classList.toggle("error", true)
    }
  }
}
